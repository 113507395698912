import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';

export const ReadMoreContainer = styled(motion.div)<ReadMoreContainerProps>`
  display: none;
  position: absolute;
  margin: 200px 0 0;
  left: 42px;
  bottom: 42px;
  width: 120px;
  height: 120px;
  z-index: 10;

  ${({ alignRight }) => alignRight && css`
    right: 42px;
    left: auto;
    top: calc(90vh - 60px);
    margin: 0;
  `}

  ${({ animateScroll }) => animateScroll && css`
    position: fixed;
    margin: 0;
  `}

  ${media.desktop`
    display: block;
  `}
`;

type ReadMoreContainerProps = {
  alignRight?: boolean;
  animateScroll?: boolean;
};
