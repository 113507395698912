import styled from 'styled-components';

import { media } from 'styles/utils';
import { Text } from 'common/typography';

export const AllRightsReservedContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const AllRightsReservedExtendedText = styled(Text)`
  opacity: 0.8;
  font-size: 3vw;

  ${media.tablet`
    font-size: 2vw;
  `}

  ${media.desktop`
    font-size: 1vw;
  `}
`;

export const AllRightsReservedWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  z-index: 2;
  padding: 5px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.springWood};
  opacity: 0.5;
`;
