import * as React from 'react';

import { gtagTrackAndOpen } from 'services/trackingHelpers';

import {
  ExtendedButton,
  ExtendedText,
} from './styled';

export const BuyNowBlock: React.FC = () => {
  return (
    <div>
      <ExtendedButton
        variant="primary"
        href="https://shop.nowatch.com?utm_medium=referral&utm_source=nowatch.com&utm_content=home-page-pricing-device-buy-now"
        onClick={(event) => gtagTrackAndOpen({
          event,
          url: 'https://shop.nowatch.com?utm_medium=referral&utm_source=nowatch.com&utm_content=home-page-pricing-device-buy-now',
          eventName: 'click_pricing_device_buy_num',
          eventCategory: 'ecommerce',
          eventLabel: window.location.pathname,
        })}
      >
        Buy now
      </ExtendedButton>
      <ExtendedText $margin="0 20px" $weight="500">Starting at &euro;427</ExtendedText>
    </div>
  );
};
