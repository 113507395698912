import { ValidationRule, Validate } from 'react-hook-form';

type ValidationMap = {
  [name: string]: Validate<string> | ValidationRule | Record<string, ValidationRule>;
};

// Use a function here, so the values of the object van be typed, but the keys can be inferred
const createValidationMap = <T extends ValidationMap>(validation: T) => validation;

export const validation = createValidationMap({
  required: {
    required: 'This field is required',
  },
  phone: {
    pattern: {
      value: /^\+?[0-9-() ]*$/,
      message: 'Phone number is invalid',
    },
    maxLength: {
      value: 14,
      message: 'Phone number is too long',
    },
    minLength: {
      value: 10,
      message: 'Phone number is too short',
    },
  },
  email: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Email address is invalid',
    },
  },
  number: {
    pattern: {
      value: /^\d+$/,
      message: 'Only numbers allowed',
    },
  },
  date: {
    pattern: {
      value: /^(0?[1-9]|[12][0-9]|3[01])[-](0?[1-9]|1[012])[-]\d{4}$/,
      message: 'Date is invalid',
    },
  },
  address: {
    pattern: {
      value: /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]+$/,
      message: 'Address is invalid',
    },
  },
  password: {
    minLength: {
      value: 8,
      message: 'Password needs to contain at least 8 characters',
    },
  },
  textarea: {
    maxLength: {
      value: 255,
      message: 'Too many characters',
    },
  },
  postalCode: {
    pattern: {
      value: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
      message: 'Postal code is invalid',
    },
  },
  positiveInterger: (value: string) => {
    const isValid = parseInt(value) >= 0;
    return !value || isValid || 'Value needs to be more than 0';
  },
  kvkNumber: {
    maxLength: {
      value: 8,
      message: 'KvK-number must contain 8 characters',
    },
    minLength: {
      value: 8,
      message: 'KvK-number must contain 8 characters',
    },
  },
  time: {
    pattern: {
      value: /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      message: 'Time is invalid',
    },
  },
});
