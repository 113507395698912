import * as React from 'react';

import * as i from 'types';
import theme from 'styles/theme';

export const HeaderColorContext = React.createContext<HeaderColorContextProps | null>(null);

export const HeaderColorProvider = ({ children }: HeaderColorProviderProps) => {
  const defaultHeaderColors = {
    background: 'transparent',
    text: theme.colors.black,
  } as i.HeaderColors;
  const [headerColors, setHeaderColors] = React.useState<i.HeaderColors>(defaultHeaderColors);

  const resetHeaderColors = () => {
    setHeaderColors(defaultHeaderColors);
  };

  return (
    <HeaderColorContext.Provider value={{
      headerColors,
      defaultHeaderColors,
      setHeaderColors,
      resetHeaderColors,
    }}>
      {children}
    </HeaderColorContext.Provider>
  );
};

type HeaderColorContextProps = {
  headerColors: i.HeaderColors;
  defaultHeaderColors: i.HeaderColors;
  setHeaderColors: (colors: i.HeaderColors) => void;
  resetHeaderColors: () => void;
};

export type HeaderColorProviderProps = {
  children: React.ReactNode;
};
