import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from '@sentry/react';

import GlobalStyle from 'styles';
import theme from 'styles/theme';
import { handleErrorBoundaryCapture } from 'services/sentry';
import { Meta, HeaderMenu, OrderNowButton } from 'common/layout';
import { BackToTop } from 'common/interaction';
import { HeaderColorProvider } from 'services/context/headerColorContext';

import { Footer } from '../Footer';
import { Container } from './styled';

export const Template: React.FC = ({ children }) => {
  return (
    <ErrorBoundary beforeCapture={handleErrorBoundaryCapture}>
      <HeaderColorProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Meta />
          <Container>
            <HeaderMenu />
            {/* <OrderNowButton isFloating /> */}
            {children}
          </Container>
          <BackToTop />
          <Footer />
        </ThemeProvider>
      </HeaderColorProvider>
    </ErrorBoundary>
  );
};
