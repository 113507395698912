import theme from 'styles/theme';

export const menuVariant = {
  open: {
    transform: 'translateX(0vw)',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 1.2,
    },
  },
  closed: {
    transform: 'translateX(100vw)',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 1.2,
    },
  },
};

export const arrowVariant = {
  inactive: {
    x: 'calc(5vh + 2.2vw)', // size of arrow + margin
  },
  active: {
    x: 0,
  },
};

export const listItemVariant = {
  inactive: {
    backgroundPosition: 'bottom',
    backgroundImage: 'none',
  },
  active: {
    backgroundImage: `linear-gradient(to top, ${theme.colors.gray} 100%, transparent 100%)`,
    backgroundSize: '100% 200%',
    backgroundPosition: 'top',
    transition: {
      duration: 0.2,
      bounce: 0,
    },
  },
};
