import styled from 'styled-components';
import { motion } from 'framer-motion';

// We use a background image gradient because the top needs to be springWood and bottom needs
// to be black looking like it's part of the footer, so we set a hard gradient
export const BackgroundContainer = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.springWood} 50%,
    ${({ theme }) => theme.colors.black} 50%
  );
`;

export const BackToTopContainer = styled(motion.div)`
  width: 100%;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.springWood};
  overflow-y: hidden;
  transition: 0.3s ease;
  border-radius: 24px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.sand.lighter};
  }
`;

export const ContentWrapper = styled(motion.div)`
  width: 100%;
  height: 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ElementGroup = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const IconContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg) scale(1.6);
`;
