import styled, { css } from 'styled-components';

import Logo from 'vectors/internal/logo_nowatch.svg';

export const SocialShareContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ExtendedLogo = styled(Logo)`
  width: 130px;
  margin: 30px auto;
  display: block;
`;

export const SocialLogoContainer = styled.div<SocialLogoContainerProps>`
  margin: 0 8px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
    width: 32px;
    color: ${({ theme }) => theme.colors.sand};
    transition: color 0.2s ease;
    cursor: pointer;

    :hover {
      color: ${({ theme }) => theme.colors.sand.dark};
    }

    ${({ variant }) => variant === 'black' && css`
      color: ${({ theme }) => theme.colors.sand.light};
    `}
`;

type SocialLogoContainerProps = {
  variant?: 'sand' | 'black';
};
