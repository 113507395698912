import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const duration = 0.5;
const variants = {
  initial: {
    opacity: 0.75,
  },
  animate: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: duration,
      ease: 'easeIn',
    },
  },
  exit: {
    opacity: 0,
  },
};

export const BlogTransitionLayout: React.FC<BlogTransitionLayoutProps> = ({ children, location }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.main
        key={location.pathname}
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        {children}
      </motion.main>
    </AnimatePresence>
  );
};

type BlogTransitionLayoutProps = {
  children: React.ReactNode;
  location: Location;
};

