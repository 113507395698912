import styled, { css } from 'styled-components';
import * as i from 'types';

export const InputFieldCss = css<InputProps>`
  color: ${({ theme, dark }) => dark ? theme.colors.black : theme.colors.sand.light};
  width: 100%;
  height: 40px;
  padding: 8px 0;s
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 0;
  background-color: transparent;
  -webkit-appearance: none;
  line-height: normal;
  overflow: hidden;
  font-family: ${({ theme }) => theme.fonts.suisseIntl};

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }

  &:active,
  &:focus {
    caret-color: ${({ theme, dark }) => dark ? theme.colors.black : theme.colors.sand.light};
    border-color: ${({ theme, dark }) => dark ? theme.colors.black : theme.colors.sand.light};
    outline: none;
  }

  &[readonly],
  &[disabled] {
    border-color: ${({ theme }) => theme.colors.sand};
    color: ${({ theme }) => theme.colors.gray};
    background-color: #F2F2F2;
  }

  ${({ error }) => error && css`
    border-color: ${({ theme }) => theme.colors.red};

    &:active,
    &:focus {
      caret-color: ${({ theme }) => theme.colors.red};
      border-color: ${({ theme }) => theme.colors.red};
      outline: none;
    }
  `};

  ${({ hasIcon }) => hasIcon && css`
    padding-left: 60px;
  `}
`;

export type InputProps = Pick<i.DefaultInputProps, 'autoFocus'> & {
  error?: boolean;
  hasIcon?: boolean;
  as?: 'textarea';
  dark?: boolean;
};

export const StyledInput = styled.input`
  ${InputFieldCss};
`;

export const InputIcon = styled.div<IconWrapperProps>`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    max-width: 30px;
    max-height: 100%;
    fill: #000000;
  }

  ${({ readOnly }) => readOnly && css`
    opacity: .4;
  `};
`;

type IconWrapperProps = Pick<i.DefaultInputProps, 'readOnly'>;

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;
  width: 100%;

  ${({ iconPosition }) => iconPosition === 'right' && css`
    ${StyledInput} {
      padding: 0 60px 0 8px;
    }

    ${InputIcon} {
      left: auto;
      right: 16px;
    }
  `}
`;

type InputWrapperProps = Pick<i.DefaultInputProps, 'iconPosition'>;
