import OggRoman from 'fonts/Ogg-Roman.otf';
import SuisseIntl from 'fonts/SuisseIntl-Regular.otf';
import SuisseIntlMedium from 'fonts/SuisseIntl-Medium.otf';

export const Fonts = `
  @font-face {
    font-family: 'Ogg Roman';
    src: url('${OggRoman}') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('${SuisseIntl}') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('${SuisseIntlMedium}') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
`;
