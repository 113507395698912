import * as React from 'react';

import { InternalLink } from 'common/interaction';
import {
  AllRightsReservedContainer,
  AllRightsReservedExtendedText,
  AllRightsReservedWrapper,
} from './styled';

const currentYear = new Date().getFullYear();

export const AllRightsReserved: React.FC = () => {
  return (
    <AllRightsReservedContainer>
      <AllRightsReservedWrapper>
        <AllRightsReservedExtendedText $margin="0">
          NOWATCH BV {currentYear} &copy; All rights reserved
          {/* Temporarily disabled old terms */}
          {/* - <InternalLink to="/terms">Terms</InternalLink> */}
          &nbsp;- <InternalLink to="/privacy">Privacy</InternalLink>
        </AllRightsReservedExtendedText>
      </AllRightsReservedWrapper>
    </AllRightsReservedContainer>
  );
};
