import { Button } from 'common/interaction';
import { Text } from 'common/typography';

import styled from 'styled-components';
import { media } from 'styles/utils';

export const CookieConsentContainer = styled.div<CookieConsentContainerProps>`
  position: fixed;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 24px;
  width: calc(100% - 16px);
  max-width: 688px;
  display: flex;
  flex-direction: column;
  z-index: 3;
  bottom: 8px;
  right: 8px;
  transition: all 0.4s ease-in-out;
  margin-bottom: ${({ isVisible }) => (isVisible ? '0' : '-400px')};

  ${media.desktop`
    bottom: 32px;
    right: 32px;
  `}
`;

type CookieConsentContainerProps = {
  isVisible: boolean;
};

export const ExtendedText = styled(Text)`
  font-size: 18px;
  margin-top: 0;

  ${media.desktop`
    font-size: 24px;
  `}
`;

export const ExtendedParagraph = styled(Text)`
  font-size: 14px;
  margin-top: 0;
`;

export const ExtendedButton = styled(Button)`
  align-self: flex-end;
  font-size: 18px;
  width: 140px;
  height: 40px;
`;
