import * as React from 'react';

import { PreloaderContext } from 'services/context/preloaderContext';

export const usePreloader = () => {
  const context = React.useContext(PreloaderContext);

  if (!context) {
    throw new Error('Components should be rendered inside the PreloaderContext.Provider component');
  }

  return context;
};
