import * as React from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import WhatsAppIcon from 'vectors/socials/whatsapp.svg';
import TwitterIcon from 'vectors/socials/twitter.svg';
import LinkedInIcon from 'vectors/socials/linkedin.svg';
import FacebookIcon from 'vectors/socials/facebook.svg';
import { StaticHeading } from 'common/typography';

import { SocialLogoContainer, SocialShareContainer } from './styled';

export const SocialShare: React.FC<SocialShareProps> = ({ title, url, variant = 'sand', includeHeading  }) => {
  return (
    <SocialShareContainer>
      {includeHeading && (
        <StaticHeading as="h6">
          Share on social
        </StaticHeading>
      )}
      <SocialLogoContainer variant={variant}>
        <WhatsappShareButton
          url={url}
          title={title}
        >
          <WhatsAppIcon />
        </WhatsappShareButton>
        <TwitterShareButton
          url={url}
          title={title}
        >
          <TwitterIcon />
        </TwitterShareButton>
        <LinkedinShareButton
          url={url}
          summary={title}
        >
          <LinkedInIcon />
        </LinkedinShareButton>
        <FacebookShareButton
          url={url}
          title={title}
        >
          <FacebookIcon />
        </FacebookShareButton>
      </SocialLogoContainer>
    </SocialShareContainer>
  );
};

type SocialShareProps = {
  title: string;
  url: string;
  includeHeading?: boolean;
  variant?: 'sand' | 'black';
};
