import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const OrderNowButtonCircle = styled.div`
  height: 1.2vw;
  width: 1.2vw;
  border: 1px solid ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 100%;
  margin-right: 2vw;
  transition: background-color .2s ease;

  ${media.desktop`
    height: .8vw;
    width: .8vw;
    margin-right: 0.4vw;
  `}
`;

export const OrderNowButtonLink = styled.a`
  text-decoration: none;
  line-height: calc(1vw + 20px);
  color: ${({ theme }) => theme.colors.black};
  z-index: 9;
  display: flex;
  align-items: center;
  transition: transform .2s ease;
  transform: translateX(-50%);
  font-size: 11px;
  text-transform: uppercase;
  position: fixed;
  right: -10.5vw;
  bottom: 15vh;
  top: auto;
  left: auto;
  transform: rotate(90deg);
  background-color: ${({ theme }) => theme.colors.springWood};
  border-radius: 8px;
  font-size: 3vw;
  padding: 4px 15px;

  &:hover {
    transform: translateY(-.5vw) rotate(90deg);

    ${OrderNowButtonCircle} {
      background-color: transparent;
    }
  }

  ${media.tablet`
    font-size: 1.5vw;
    right: -5.5vw;
    top: 45vh;
    bottom: auto;
  `}

  ${media.desktop`
    padding: 4px 15px;
    transform: none;
    right: -3.5vw;
    left: auto;
    transform: rotate(90deg);
    font-size: 1vw;
  `}
`;
