import * as React from 'react';

import { InternalLink, ExternalLink } from 'common/interaction';
import { ButtonProps, ButtonVariantsType } from './types';
import { StyledButton, StyledButtonLoader, ButtonIcon } from './styled';

const ButtonLoader: React.FC<ButtonLoaderProps> = ({ variant }) => (
  <StyledButtonLoader variant={variant}>
    <div />
    <div />
    <div />
  </StyledButtonLoader>
);

type ButtonLoaderProps = {
  variant?: ButtonVariantsType;
};

const ButtonContent: React.FC<ButtonProps> = ({ children, icon, $isLoading, variant }) => (
  <>
    {$isLoading ? (
      <ButtonLoader variant={variant} />
    ) : (
      <>
        {icon && (
          <ButtonIcon>
            {icon}
          </ButtonIcon>
        )}
        <span>{children}</span>
      </>
    )}
  </>
);

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  children, href, icon, $iconPosition = 'left', size = 'auto', $isLoading,
  onClick, to, urlTarget, disabled, variant, $iconOnlyOnMobile, small, traditional, ...otherProps
}, ref) => {
  const styledButtonProps = {
    disabled,
    $iconOnly: !children && icon,
    $iconOnlyOnMobile,
    $iconPosition,
    $isLoading,
    variant,
    size,
    small,
    traditional,
    ref,
  };

  const buttonContentProps = {
    children,
    icon,
    $isLoading,
    variant,
  };

  if (href) {
    return (
      <StyledButton
        as={ExternalLink}
        to={href}
        onClick={onClick ? onClick : undefined}
        target={`_${urlTarget || '_self'}`}
        {...styledButtonProps}
        {...otherProps}
      >
        <ButtonContent {...buttonContentProps} />
      </StyledButton>
    );
  }

  if (to) {
    return (
      <StyledButton
        as={InternalLink}
        to={to}
        onClick={onClick ? onClick : undefined}
        target={`_${urlTarget || '_self'}`}
        {...styledButtonProps}
        {...otherProps}
      >
        <ButtonContent {...buttonContentProps} />
      </StyledButton>
    );
  }

  return (
    <StyledButton
      onClick={onClick}
      {...styledButtonProps}
      {...otherProps}
    >
      <ButtonContent {...buttonContentProps} />
    </StyledButton>
  );
});
