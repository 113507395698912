import styled from 'styled-components';
import { motion } from 'framer-motion';

import { InternalLink } from 'common/interaction';

export const MotionContainer = styled(motion.div)`
  width: fit-content;
`;

export const Underline = styled(motion.div)`
  height: 1.5px;
  background-color: ${({ theme }) => theme.colors.sand.dark};
`;

export const InternalNavigationLink = styled(InternalLink)<FooterNavigationLinkProps>`
  color: ${({ theme }) => theme.colors.sand.light};
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  font-size: ${({ size }) => size || 16}px;
  font-weight: 500;
  line-height: 20.75px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.sand.dark};
  }

  > div {
    width: 100%;
  }
`;

type FooterNavigationLinkProps = {
  size?: 14 | 16;
};
