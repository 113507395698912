import * as React from 'react';

import { sizes } from 'styles/utils';
import { useMediaQuery } from 'services/hooks';

export const useDevice = (debounce = 500) => {
  const [determined, setDetermined] = React.useState(false);

  // By default we assume that we are on mobile, this will make sure SSR renders
  // the mobile view. Once someone loads the website on desktop the React.useEffect
  // will pick it up and render the desktop version. Prefer to keep the data and
  // loading as lean as possible for mobile. No desktop visuals being loaded or
  // desktop animations running in the background on hidden elements.
  const [isMobile, setIsMobile] = React.useState(true);
  const [isTablet, setIsTablet] = React.useState(false);
  const [isDesktop, setIsDesktop] = React.useState(false);
  const [isLarge, setIsLarge] = React.useState(false);

  const [mediaActive] = useMediaQuery(
    {
      tablet: `(max-width: ${sizes.desktop - 1}px)`,
      mobile: `(max-width: ${sizes.tablet - 1}px)`,
      desktop: `(min-width: ${sizes.desktop}px)`,
      large: `(min-width: ${sizes.large}px)`,
    },
    debounce,
  );

  React.useEffect(() => {
    setIsMobile(mediaActive === 'mobile');
    setIsTablet(mediaActive === 'tablet');
    setIsDesktop(mediaActive === 'desktop');
    setIsLarge(mediaActive === 'large');
    setDetermined(true);
  }, [mediaActive]);

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLarge,
    determined,
  };
};
