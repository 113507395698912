import * as React from "react";
import TechRadar from "vectors/logos/techradar.svg";
import Departures from "vectors/logos/departures.svg";
import TheVerge from "vectors/logos/the_verge.svg";
import FinancialTimes from "vectors/logos/financial_times.svg";
import Dezeen from "vectors/logos/dezeen.svg";
import CNet from "vectors/logos/cnet.svg";

const pressItems = [
  {
    logo: TechRadar,
    quote:
      "“An attractive, stress-monitoring device that doesn't just ape an analog watch: it has a style identity all of its own.”",
  },
  {
    logo: Departures,
    quote: "“A wearable for experience life, not optimizing it.”",
  },
  {
    logo: FinancialTimes,
    quote:
      "“NOWATCH nudged me into thinking more about my mental health, almost as if I was being watched over by a kindly mentor.”",
  },
  {
    logo: Dezeen,
    quote:
      "“NOWATCH's vibrations are supportive so users can recognise when stress is building and manage it better.”",
  },
  {
    logo: CNet,
    quote:
      "“If you've ever wanted a smartwatch but don't love how distracting they can be, then you may be interested in NOWATCH.”",
  },
  {
    logo: TheVerge,
    quote:
      "“...the vibrations were effective in getting me to pause and take a few calming deep breaths. I liked it.”"
  },
];

export const PressMarquee: React.FC = () => {
  return (
    <div className="relative flex overflow-x-hidden border-y border-sand">
      <div className="py-4 animate-marquee whitespace-nowrap">
        {pressItems.map(({ logo: Logo, quote }, index) => (
          <span key={index}>
            <Logo className="inline-block mx-2" />
            <span className="mx-2">{quote}</span>
            <span className="mx-2">•</span>
          </span>
        ))}
      </div>

      <div className="absolute top-0 py-4 animate-marquee2 whitespace-nowrap">
        {pressItems.map(({ logo: Logo, quote }, index) => (
          <span key={index}>
            <Logo className="inline-block mx-2" />
            <span className="mx-2">{quote}</span>
            <span className="mx-2">•</span>
          </span>
        ))}
      </div>
    </div>
  );
};
