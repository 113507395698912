import * as i from 'types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { InternalLink } from 'common/interaction';
import { media } from 'styles/utils';

export const NavigationContainer = styled(motion.nav)`
  display: flex;
  align-items: center;
`;

export const NavigationButton = styled.button<NavigationButtonProps>`
  appearance: none;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  position: relative;
  border: 0;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;

  ${media.desktop`
    height: 30px;
    width: 30px;
  `}

  svg {
    width: 100%;
    transition: color 0.2s ease;
    color: ${({ isNavigationOpen, $colors, $defaultHeaderColors }) => isNavigationOpen ? $defaultHeaderColors.text : $colors.text};
  }
`;

type NavigationButtonProps = {
  isNavigationOpen: boolean;
  $colors: i.HeaderColors;
  $defaultHeaderColors: i.HeaderColors;
};

export const NavigationMenu = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.colonnadeStone};
  z-index: -1;
  overflow: hidden;
`;

export const NavigationMenuList = styled.ul`
  padding: 0;
  margin: 46px 0 0;

  ${media.desktop`
    margin: 62px 0 0;
  `}
`;

export const NavigationMenuItem = styled(motion.li)`
  height: 13vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 0 2.2vw;
  position: relative;

  svg {
    margin-left: 32px;
    width: 3.5vh;
  }

  height: calc(92.5vh / 8);

  ${media.desktop`
    height: calc(92.5vh / 7);

    svg {
      width: 5vh;
    }
  `}
`;

export const NavigationMenuItemLink = styled(InternalLink)<NavigationMenuItemTextProps>`
  font-size: 6vh;
  font-family: ${({ theme }) => theme.fonts.oggRoman};
  text-decoration: none;
  color: ${({ theme, $isCurrentPage }) => $isCurrentPage ? theme.colors.tapa : theme.colors.black};
  display: flex;
  align-items: center;
  text-align: right;
  width: 100%;
  text-transform: uppercase;

  ${media.desktop`
    font-size: 9.5vh;
  `}
`;

type NavigationMenuItemTextProps = {
  $isCurrentPage: boolean;
};
