import { createGlobalStyle } from 'styled-components';

import { isServer } from 'services/isServer';

import { Fonts } from './fonts';

// Fonts are not applied in the createGlobalStyle function to solve
// https://github.com/styled-components/styled-components/issues/1593
if (!isServer) {
  const fontStyle = document.createElement('style');
  fontStyle.textContent = Fonts;
  document.head.appendChild(fontStyle);
}

const globalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.suisseIntl};
    background-color: ${({ theme }) => theme.colors.springWood};
  }

  ::selection {
    background: ${({ theme }) => theme.colors.springWood.dark};
  }
`;

export default globalStyle;
