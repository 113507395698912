import * as React from 'react';
import { useCycle } from 'framer-motion';
import { navigate } from 'gatsby';
import { Navigation } from 'common/layout';
import { useHeaderColor } from 'services/hooks';
import { Button } from 'common/interaction';
import { HeaderMenuContainer, ExtendedLogo, HeaderMenuRight, BackgroundFilterBlur } from './styled';
import { StaticButtonText } from '../../typography';
import { buyNowNavigationClicked } from 'services/events';

export const HeaderMenu: React.FC = () => {
  const [isNavigationOpen, setNavigationOpen] = useCycle(false, true);
  const { headerColors, defaultHeaderColors } = useHeaderColor();

  const scrollToTop = () => {
    if (document.location.pathname === '/') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      navigate('/');
    }
  };

  return (
    <HeaderMenuContainer
      aria-label={'Main header'}
      $colors={headerColors}
      $defaultHeaderColors={defaultHeaderColors}
      isNavigationOpen={isNavigationOpen}
      className='z-50'
    >
      <a href="https://nowatch.com">
        <ExtendedLogo />
      </a>
      <BackgroundFilterBlur />
      <HeaderMenuRight>
        <Button
          small
          variant={headerColors.text === '#FFFFFF' ? 'white' : 'transparent'}
          href="https://nowatch.com/product"
          target='_self'
          onClick={ () => buyNowNavigationClicked() }
        >
          <StaticButtonText>Buy NOWATCH</StaticButtonText>
        </Button>
      </HeaderMenuRight>
    </HeaderMenuContainer>
  );
};
