import * as React from 'react';

import { ExternalLink, InternalLink } from 'common/interaction';

import { InternalNavigationLink, Underline, MotionContainer } from './styled';
import { variants } from './motion';

export const FooterLink: React.FC<FooterLinkProps> = ({ size = 16, to, external = false, title }) => {
  return (
    <>
      <MotionContainer whileHover="hover" initial="init" animate="init">
        <InternalNavigationLink
          as={external ? ExternalLink : InternalLink}
          {...{ to, size }}
        >
          {title}
          <Underline {... { variants }} />
        </InternalNavigationLink>
      </MotionContainer>
    </>
  );
};

type FooterLinkProps = {
  to: string;
  title: string;
  size?: 14 | 16;
  external?: boolean;
};
