import * as React from 'react';
import { useTransform, useMotionTemplate } from 'framer-motion';

import { useScrollDistanceForElementInView } from 'services/hooks';
import { FooterSocial } from 'common/layout';
import { StaticButtonText } from 'common/typography';
import { FooterLink, NewsletterSubscription, ExternalLink } from 'common/interaction';

import {
  FooterContainer, FooterMotionContainer, NavigationContainer, Column, LogoIcon,
  AddressContainer, ExtendedText, FooterNavigationContainer,
  MetaDataContainer, Row, SupportContainer,
} from './styled';

export const Footer: React.FC = () => {
  const [footerHeight, setFooterHeight] = React.useState(0);
  const [elementRef, scrolledDistance, scrolledDistanceWithinViewport] = useScrollDistanceForElementInView();

  React.useEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.offsetHeight;
      setFooterHeight(height);
    }
  }, []);

  // Use height of the footer within viewport to animate, ergo adaptive to different screens
  const footerPositionY = useTransform(
    scrolledDistanceWithinViewport, [0, footerHeight], [-0.3 * footerHeight, 0]);
  const templateFooterPositionY = useMotionTemplate`translateY(${footerPositionY}px)`;

  return (
    <FooterContainer ref={elementRef}>
      <FooterMotionContainer style={{ transform: templateFooterPositionY }}>
        <FooterNavigationContainer>
          <LogoIcon />
          <NavigationContainer>
            <Column>
              <FooterLink to="https://nowatch.com" title="Home" />
              <FooterLink external to="https://nowatch.com/product" title="Shop" />
              <FooterLink to="/about/" title="About" />
            </Column>
            <Column>
              <FooterLink external to="https://careers.nowatch.com" title="Careers" />
              <FooterLink to="/" title="Blog" />
            </Column>
          </NavigationContainer>
          <div>
            <ExtendedText $uppercase>
              stay in the loop
            </ExtendedText>
            <NewsletterSubscription />
            <SupportContainer>
              <ExtendedText $uppercase>
                Support
              </ExtendedText>
              <ExternalLink to="https://support.nowatch.com/kb/en/questions-answers-248191" $color={'gray'}>
                <StaticButtonText $weight={500}>
                  Questions & Answers
                </StaticButtonText>
              </ExternalLink>
              <ExternalLink to="mailto:support@nowatch.com" $color={'gray'}>
                <StaticButtonText $weight={500}>
                  support@nowatch.com
                </StaticButtonText>
              </ExternalLink>
            </SupportContainer>
            <AddressContainer>
              <ExternalLink to="https://houseofnow.nowatch.com">
                <ExtendedText $uppercase>Flagship Store</ExtendedText>
              </ExternalLink>
              <ExtendedText>Prinsengracht 455</ExtendedText>
              <ExtendedText>1016 HN Amsterdam</ExtendedText>
              <ExtendedText>The Netherlands</ExtendedText>
              <ExternalLink to="https://houseofnow.nowatch.com" $color={'gray'}>
                <StaticButtonText $weight={500}>
                  Visit the House of Now
                </StaticButtonText>
              </ExternalLink>
            </AddressContainer>
          </div>
        </FooterNavigationContainer>
        <MetaDataContainer>
          <Row $gap={24}>
            <FooterLink external size={14} to="https://brand.nowatch.com/share/92C43572-D553-4108-85EEE562662134C9/" title="Press" />
            <FooterLink size={14} to="/privacy/" title="Privacy Policy" />
            <FooterLink external size={14} to="/terms-and-conditions/" title="Terms and Conditions" />
          </Row>
          <Row $gap={16} $flexItemsCenter>
            <ExtendedText $size={14}>Follow us on Instagram: </ExtendedText>
            <FooterSocial />
          </Row>
          <ExtendedText $size={14}>
            NOWATCH BV 2024 @ All rights reserved
          </ExtendedText>
        </MetaDataContainer>
      </FooterMotionContainer>
    </FooterContainer>
  );
};
