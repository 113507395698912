export const BackToTopVariant = {
  init: {
    y: 0,
    transition: {
      duration: 0.4,
      type: 'spring',
      bounce: 0.3,
    },
  },
  hover: {
    y: '-50%',
    transition: {
      duration: 0.4,
      type: 'spring',
      bounce: 0.3,
    },
  },
};
