import * as React from 'react';
import { useForm } from 'react-hook-form';

import { signUpToNewsletter } from 'services/newsletterSubscriptionHelper';
import { validation } from 'services/validation';
import { Input } from 'common/form';
import { Button } from 'common/interaction';
import { StaticText, StaticButtonText } from 'common/typography';

import { NewsletterSubscriptionContainer, NewsLetterSubscriptionInputContainer } from './styled';

export const NewsletterSubscription: React.FC<NewsletterSubscriptionProps> = ({ isBlock, margin }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [succeeded, setSucceeded] = React.useState(false);
  const {
    register, handleSubmit, setError, reset, formState: { errors, isDirty },
  } = useForm<{ email: string }>({
    mode: 'onSubmit',
  });

  const onSubmit = async (form: { email: string }) => {
    setIsSubmitting(true);

    if (form.email) {
      const result = await signUpToNewsletter(form.email);
      if (result) {
        setIsSubmitting(false);
        setSucceeded(true);
      } else {
        setError('email', { message: 'Couldn\'t sign up. Please send an email to info@nowatch.com' });
      }
    } else {
      setError('email', { message: 'Missing email' });
    }
    setIsSubmitting(false);
  };

  const onReset = () => {
    reset();
    setSucceeded(false);
  };

  return (
    <NewsletterSubscriptionContainer isBlock={isBlock} margin={margin}>
      {isBlock && (
        <>
          <StaticText $size="lg" $uppercase $isCentered $margin="0">
            {succeeded ? 'Thank you' : 'Sign up for our newsletter'}
          </StaticText>
          {!succeeded && (
            <StaticText $size="sm" $isCentered>
              Join our mailing list and we will keep you up to date with news on NOWATCH.
            </StaticText>
          )}
        </>
      )}
      {!succeeded ? (
        <>
          <NewsLetterSubscriptionInputContainer
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              placeholder="Enter your email"
              error={errors.email}
              {...register('email', { ...validation.email, ...validation.required })}
              dark={isBlock}
            />
            <Button
              variant={isBlock ? 'primary' : 'sand'}
              size="fullWidth"
              type="submit"
              disabled={!isDirty}
              $isLoading={isSubmitting}
            >
              <StaticButtonText $uppercase={false}>
                Subscribe
              </StaticButtonText>
            </Button>
          </NewsLetterSubscriptionInputContainer>
          <StaticText $size="sm" $color="gray" $margin="0">
            By signing up, I agree with the&nbsp;
            <a href="https://nowatch.com/privacy" target="_blank" rel="noreferrer">data protection policy</a>
            &nbsp;of NOWATCH.
          </StaticText>
        </>
      ) : (
        <>
          <StaticText
            $color={isBlock ? 'black' : 'white'}
            $margin={isBlock ? '8px 0 16px 0' : '8px 0 0 0'}
            $size="lg"
          >
            Thanks for subscribing!
          </StaticText>
          {isBlock && (
            <Button
              variant={isBlock ? 'primary' : 'sand'}
              type="button"
              small
              onClick={onReset}
            >
              <StaticButtonText $uppercase={false}>
                Add new address
              </StaticButtonText>
            </Button>
          )}
        </>
      )}
    </NewsletterSubscriptionContainer>
  );
};

type NewsletterSubscriptionProps = {
  isBlock?: boolean;
  margin?: {
    desktop?: string;
    mobile?: string;
  };
};
