import styled, { css, keyframes } from 'styled-components';

import { media } from 'styles/utils';

import { ButtonProps, ButtonVariantsType } from './types';

export const buttonLoaderAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

export const StyledButtonLoader = styled.div<StyledButtonLoaderProps>`
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;

  & > div {
    width: 6px;
    height: 6px;
    margin-right: 3px;
    background-color: ${({ theme, variant }) => variant === 'sand' ? theme.colors.black : theme.colors.white};
    border-radius: 100%;
    animation: ${buttonLoaderAnimation} 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }
`;

type StyledButtonLoaderProps = {
  variant?: ButtonVariantsType;
};

export const ButtonIcon = styled.div`
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;
`;

export const StyledButton = styled.button<ButtonProps>`
  width: auto;
  min-width: 0;
  height: 8vw;
  position: relative;
  padding: 0 6vw;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 3vw;
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  line-height: normal;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 6vw;
  border: none;
  transition: all 0.5s ease;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.grey4};
    }
  }

  svg {
    max-width: 24px;
    max-height: 24px;
  }

  ${media.tablet`
    height: 3.5vw;
    font-size: 2vw;
    padding: 0 4vw;
  `}

  ${media.desktop`
    height: 2.5vw;
    font-size: 1.2vw;
    padding: 0 2vw;

    + button {
      margin: 0 0 0 8px;
    }
  `}

  ${({ $iconOnly }) => $iconOnly && css`
    width: 48px;

    ${ButtonIcon} {
      margin: 0;
    }
  `}

  ${({ $iconOnlyOnMobile }) => $iconOnlyOnMobile && css`
    width: 48px;

    ${ButtonIcon} {
      margin: 0;
    }

    > span {
      display: none;
    }

    ${media.tablet`
      width: auto;

      > span {
        display: block;
      }
    `}
  `}

  ${({ $iconPosition }) => $iconPosition === 'right' && css<ButtonProps>`
    flex-direction: row-reverse;

    ${ButtonIcon} {
      margin: 0 0 0 8px;

      ${({ $iconOnlyOnMobile }) => $iconOnlyOnMobile && css`
        margin: 0;

        ${media.tablet`
          margin: 0 0 0 8px;
        `}
      `}
    }
  `}

  ${({ size }) => size === 'auto' && css`
    width: auto;
  `}

  ${({ size }) => size === 'fullWidth' && css`
    width: 100%;
  `}

  ${({ $isLoading }) => $isLoading && css`
    cursor: not-allowed;
  `}

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.tapa};
  `};

  ${({ variant }) => variant === 'secondary' && css`
    border: none;
    opacity: .4;
  `}

  ${({ variant }) => variant === 'transparent' && css`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
    border: 2px solid ${({ theme }) => theme.colors.black};

    @media (hover: hover) {
      &:hover {
        background-color: ${({ theme }) => theme.colors.black};
        color: ${({ theme }) => theme.colors.white};
      }
    }
  `}

  ${({ variant, disabled }) => variant === 'sand' && css`
    background-color: ${({ theme }) => theme.colors.sand.light};
    color: ${({ theme }) => theme.colors.black};

    @media (hover: hover) {
      &:hover {
        background-color: ${({ theme }) => theme.colors.sand};
      }
    }

    ${disabled && css`
      opacity: 0.2;
      cursor: not-allowed;

      @media (hover: hover) {
        &:hover {
          background-color: ${({ theme }) => theme.colors.sand.light};
        }
      }
    `}
  `}

  ${({ variant }) => variant === 'white' && css`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.white};
  `}

  ${({ small }) => small && css`
    max-height: 40px;
    max-width: 210px;
    font-size: 16px;
    padding: 0 24px;

    ${media.tablet`
      font-size: 16px;
      padding: 0 24px;
    `}

    ${media.desktop`
      font-size: 16px;
      padding: 0 24px;
    `}
  `}

  ${({ traditional }) => traditional && css`
    height: auto !important;
    padding: 14px 24px !important;
  `}
`;
