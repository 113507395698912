import styled from 'styled-components';

import { Text } from 'common/typography';
import { Button } from 'common/interaction';

export const ExtendedButton = styled(Button)`
  text-transform: none;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;

export const ExtendedText = styled(Text)`
  display: inline-block;
`;
