import 'styled-components';

const theme = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    offBlack: '#1C1C1C',
    gray: '#999690',
    text: '#0F0F0F',
    springWood: Object.assign('#F6F2EE', {
      dark: '#DDD9D6',
    }),
    westar: '#DEDAD6',
    bone: '#E0D3BF',
    codGray: '#181818',
    grey4: '#4D4B48',
    woodSmoke: '#07080A',
    tapa: '#7C7672',
    colonnadeStone: '#C6BFB8',
    heronPlume: '#E6E0D8',
    manhattan: '#E3AA82',
    rosarian: '#FAEADD',
    orientalPink: '#C59B85',
    albescentWhite: '#F6E7D9',
    sand: Object.assign('#CCC8C0', {
      light: '#F5F0EB', // SAND-1
      lighter: '#E6E1D9', // SAND-2
      dark: '#B2AFA8',
    }),
    red: '#C22F3D',
  },
  fonts: {
    suisseIntl: 'Suisse Intl, sans-serif',
    oggRoman: 'Ogg Roman, sans-serif',
  },
} as const;

export default theme;
