import * as React from 'react';

import { StaticButtonText, StaticHeading, StaticText } from 'common/typography';
import { ExternalLink } from 'common/interaction';

import { SupportBlockContainer } from './styled';

export const SupportBlock: React.FC = () => {
  return (
    <SupportBlockContainer>
      <StaticHeading as="h4" $margin="0 0 8px 0" $uppercase>
        How can we help?
      </StaticHeading>
      <StaticText $margin="0 0 16px" $size="sm" $isCentered>
        If there’s anything you’d like to know more about, please get in touch.<br /> We’d love to hear from you and will respond within 24 hours on business days.
      </StaticText>
      <ExternalLink to="mailto:support@nowatch.com">
        <StaticButtonText $weight={500}>
          support@nowatch.com
        </StaticButtonText>
      </ExternalLink>
    </SupportBlockContainer>
  );
};
