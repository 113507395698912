import { api } from 'services/api';

export const signUpToNewsletter = async (
  email: string,
) => {
  const body = new URLSearchParams({
    'g': 'VqXvqZ',
    '$fields': '$first_name,$source',
    'email': email,
    '$source': 'Website',
  });

  return await api.post({
    path: 'https://manage.kmail-lists.com/ajax/subscriptions/subscribe',
    body: body,
    withFormData: true,
    headers: {
      'cache-control': 'no-cache',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then((json) => {
    return true;
  }).catch((json) => {
    return false;
  });
};
