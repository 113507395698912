import styled from 'styled-components';

import * as i from 'types';
import { media } from 'styles/utils';
import LogoNowatch from 'vectors/internal/logo_nowatch.svg';

export const ExtendedLogo = styled(LogoNowatch)`
  width: 85px;
  height: 12px;
  cursor: pointer;

  ${media.desktop`
    width: 153px;
    height: 21px;
  `}
`;

export const HeaderMenuContainer = styled.div<HeaderMenuContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 20;
  top: 0;
  height: 46px;
  padding: 0 8px 0 17px;
  background-color: ${({ $colors }) => $colors.background}99;
  color: ${({ isNavigationOpen, $colors, $defaultHeaderColors }) => isNavigationOpen ? $defaultHeaderColors.text : $colors.text};
  transition: background-color 0.2s ease;

  ${ExtendedLogo} {
    transition: ${({ isNavigationOpen }) => isNavigationOpen ? 'color 1.5s ease' : 'color 0.2s ease'};
  }

  ${media.desktop`
    height: 62px;
    padding: 0 19px 0 21px;
  `}
`;

type HeaderMenuContainerProps = {
  isNavigationOpen: boolean;
  $colors: i.HeaderColors;
  $defaultHeaderColors: i.HeaderColors;
};

export const HeaderMenuRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  ${media.desktop`
    gap: 32px;
  `}
`;


export const BackgroundFilterBlur = styled.div`
  backdrop-filter: blur(8px);
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(250,250,250,0.1);
  z-index: -1;
  left: 0;
`;
