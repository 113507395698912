import * as React from 'react';
import { useViewportScroll, useMotionValue, MotionValue } from 'framer-motion';

import { clamp } from 'services/mathHelpers';

/**
 * This component returns a MotionValue between 0 and 1. 0 when the
 * component isn't scrolled into view yet. 1 if you have fully scrolled
 * past it.
 */
export const useScrollDistanceForElementInView = <ElementType extends HTMLDivElement>() => {
  const elementRef = React.useRef<HTMLElement>(null);
  const scrolledDistance = useMotionValue(0);
  const scrolledDistanceWithinViewport = useMotionValue(0);
  const { scrollY } = useViewportScroll();

  const calculateScrollDistanceForElementInView = () => {
    const client = elementRef?.current?.getBoundingClientRect();

    let scrolledDistanceWithinElement = 0;

    // Check if the element is atleast partially in view.
    // We don't use the InteractionObserver API because that will only tell
    // us when an element is in view, not by how much, so we will have to
    // calculate that ourselves.
    if (client!.top < window.innerHeight && client!.bottom >= 0) {
      scrolledDistanceWithinElement = scrollY.get() - (elementRef?.current!.offsetTop - window.innerHeight);
    } else if (client!.bottom < 0) {
      scrolledDistanceWithinElement = client!.height + window.innerHeight;
    }

    const normalized = clamp(scrolledDistanceWithinElement, client!.height + window.innerHeight, 0);
    scrolledDistance.set(normalized);
    scrolledDistanceWithinViewport.set(scrolledDistanceWithinElement);
  };

  React.useEffect(() => {
    // Update the scrolledDistance value whenever the user is scrolling.
    const unsubscribe = scrollY.onChange(calculateScrollDistanceForElementInView);

    // Make sure the initial scrolledDistance value is correct.
    // Little hack to run the function after 1 millisecond so even after SSR
    // the animations will be on their correct state. Immediatly running the
    // function does not work.
    window.setTimeout(() => {
      calculateScrollDistanceForElementInView();
    }, 0);

    return () => unsubscribe();
  }, [elementRef]);

  return [
    elementRef,
    scrolledDistance,
    scrolledDistanceWithinViewport,
  ] as [
    React.RefObject<ElementType>,
    MotionValue<number>,
    MotionValue<number>,
  ];
};
