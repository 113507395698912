import * as i from 'types';
import styled from 'styled-components';

import { media } from 'styles/utils';

const headingSizes = {
  h1: {
    mobile: '20vw',
    tablet: '15vw',
    desktop: '9vw',
    large: '6.5vw',
  },
  h2: {
    mobile: '7vw',
    tablet: '5vw',
    desktop: '3vw',
    large: '3vw',
  },
  h3: {
    mobile: '3.5vw',
    tablet: '3.5vw',
    desktop: '1.5vw',
    large: '1.2vw',
  },
};

export const Heading = styled.h1<i.HeadingDefaultProps>`
  font-weight: 500;
  line-height: 85%;
  font-size: ${({ as }) => headingSizes[as || 'h1'].mobile};
  font-family: ${({ theme }) => theme.fonts.oggRoman};
  text-transform: uppercase;
  color: ${({ theme, color }) => theme.colors[color || 'text']};
  margin: ${({ margin }) => margin || '24px 0'};
  text-align: ${({ isCentered }) => isCentered ? 'center' : 'left'};

  ${media.tablet<i.HeadingDefaultProps>`
    font-size: ${(props) => headingSizes[props.as || 'h1'].tablet};
  `}

  ${media.desktop<i.HeadingDefaultProps>`
    font-size: ${(props) => headingSizes[props.as || 'h1'].desktop};
  `}

  ${media.large<i.HeadingDefaultProps>`
    font-size: ${(props) => headingSizes[props.as || 'h1'].large};
  `}
`;
