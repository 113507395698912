import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import LogoSvg from 'vectors/internal/logo_nowatch.svg';
import { media } from 'styles/utils';
import { Text } from 'common/typography';
import { InternalLink } from 'common/interaction';

export const FooterNavigationContainer = styled.div`
  gap: 49px;
  display: flex;
  flex-direction: column;

  ${media.desktop`
    display: grid;
    grid-template-columns: 25% 35% auto;
    gap: 81px;
  `}
`;

export const FooterContainer = styled.div`
  padding: 56px 24px;
  background-color: ${({ theme }) => theme.colors.black};
  position: relative;
  z-index: 0;

  ${media.desktop`
    padding: 56px 56px 70px 56px;
  `}
`;

export const FooterMotionContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

export const LogoIcon = styled(LogoSvg)`
  color: ${({ theme }) => theme.colors.sand.light};
  width: 150px;
`;

export const ExtendedText = styled(Text)<ExtendedTextProps>`
  color: ${({ theme }) => theme.colors.sand.light};
  margin: 0;
  font-weight: 400;
  font-size: ${({ $size }) => $size || 18}px;

  ${({ $centered }) => $centered && css`
    text-align: center;
  `}

  ${({ $uppercase }) => $uppercase && css`
    text-transform: uppercase;
  `}
`;

type ExtendedTextProps = {
  $size?: number;
  $centered?: boolean;
  $uppercase?: boolean;
};

export const NavigationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 150px;

  ${media.desktop`
    margin-bottom: 24px;
    gap: 3.75vw;
  `}
`;

export const Column = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;

  ${media.desktop`
    gap: 42px;
  `}
`;

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-style: normal;
  gap: 10px;
  margin-bottom: 32px;
`;

export const AddressContainer = styled.address`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-style: normal;
  gap: 10px;
`;

export const FooterNavigationInternalLink = styled(InternalLink)<FooterNavigationInternalLinkProps>`
  color: ${({ theme }) => theme.colors.sand.light};
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  font-size: ${({ $size }) => $size || 16}px;
  font-weight: 500;
  line-height: 20.75px;
  text-decoration: none;
`;

type FooterNavigationInternalLinkProps = {
  $size?: number;
};

export const MetaDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.desktop`
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: ${({ $gap }) => $gap || 18}px;

  ${media.desktop`
    justify-content: space-evenly;
  `}

  ${({ $flexItemsCenter }) => $flexItemsCenter && css`
    align-items: center;
  `}
`;

type RowProps = {
  $gap?: number;
  $flexItemsCenter?: boolean;
};
