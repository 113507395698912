import * as i from 'types';
import styled, { css } from 'styled-components';
import { media } from 'styles/utils';

export const Text = styled.p<i.TextDefaultProps>`
  line-height: ${({ $lineHeight }) => $lineHeight || 110}%;
  font-size: ${({ $size }) => $size || 3.5}vw;
  font-weight: ${({ $weight }) => $weight || 400};
  color: ${({ theme, $color }) => theme.colors[$color || 'text']};
  letter-spacing: -0.01em;

  ${({ $uppercase }) => $uppercase && css`
    text-transform: uppercase;
  `}

  ${({ $isCentered }) => $isCentered && css`
    text-align: center;
  `}

  ${({ $margin }) => $margin && css`
    margin: ${$margin};
  `}

  ${({ $variant }) => $variant === 'SuisseIntl' && css`
    font-family: ${({ theme }) => theme.fonts.suisseIntl};
  `}

  ${({ $inline }) => $inline && css`
    display: inline-block;
  `}

  ${({ $noSelect }) => $noSelect && css`
    user-select: none;
  `}

  & > a {
    color: ${({ theme, $color }) => theme.colors[$color || 'text']};
    text-decoration: underline;
  }

  ${media.desktop`
    font-size: 1.5vw;
  `}

  ${media.large`
    font-size: 1.2vw;
 `}
`;
