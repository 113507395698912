import * as React from 'react';

import LargeArrowIcon from 'vectors/internal/large_arrow.svg';
import { StaticText } from 'common/typography';

import {
  BackToTopContainer, IconContainer, ContentWrapper, ElementGroup, BackgroundContainer,
} from './styled';
import { BackToTopVariant } from './motion';

export const BackToTop: React.FC = () => {
  const onClick = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  return (
    <BackgroundContainer>
      <BackToTopContainer initial="init" whileHover="hover" animate="init" onClick={onClick}>
        <ContentWrapper variants={BackToTopVariant}>
          <ElementGroup>
            <IconContainer>
              <LargeArrowIcon />
            </IconContainer>
            <StaticText $size="lg">Back to top</StaticText>
          </ElementGroup>
          <ElementGroup>
            <IconContainer>
              <LargeArrowIcon />
            </IconContainer>
            <StaticText $size="lg">Back to top</StaticText>
          </ElementGroup>
        </ContentWrapper>
      </BackToTopContainer>
    </BackgroundContainer>
  );
};
