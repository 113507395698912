import styled, { css } from 'styled-components';

export const IconContainer = styled.a.attrs({
  target: '_blank',
})`
  cursor: pointer;

  > svg {
    transition: all 0.1s ease-out;
    color: ${({ theme }) => theme.colors.sand.light};

    &:hover {
      color: ${({ theme }) => theme.colors.sand.dark};
    }
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

