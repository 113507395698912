import * as i from 'types';
import * as React from 'react';
import { useViewportScroll } from 'framer-motion';

import { useHeaderColor } from './useHeaderColor';

// WARNING: make sure the elements that use this hook don't overlap with f.e. negative margins
export const useChangeHeaderColor = <ElementType extends HTMLDivElement>(colors: i.HeaderColors) => {
  const { setHeaderColors, resetHeaderColors } = useHeaderColor();
  const elementRef = React.useRef<HTMLElement>(null);
  const { scrollY } = useViewportScroll();
  const [showDifferentColor, setShowDifferentColor] = React.useState(false);

  const calculateScrollDistanceForElementInView = () => {
    const client = elementRef?.current?.getBoundingClientRect();

    if (client!.top <= 0 && Math.abs(client!.top) < client!.height) {
      setShowDifferentColor(true);
    } else if (client!.top < client!.height) {
      // Only reset the header color when user scrolled past the element
      setShowDifferentColor(false);
    }
  };

  // Only change the header colors once when the var toggles
  React.useEffect(() => {
    if (showDifferentColor) {
      setHeaderColors(colors);
    } else {
      resetHeaderColors();
    }
  }, [showDifferentColor]);

  React.useEffect(() => {
    // Update the scrolledDistance value whenever the user is scrolling.
    const unsubscribe = scrollY.onChange(calculateScrollDistanceForElementInView);

    // Make sure the initial scrolledDistance value is correct.
    // Little hack to run the function after 1 millisecond so even after SSR
    // the animations will be on their correct state. Immediatly running the
    // function does not work.
    window.setTimeout(() => {
      calculateScrollDistanceForElementInView();
    }, 0);

    return () => unsubscribe();
  }, [elementRef]);

  return [
    elementRef,
  ] as [
    React.RefObject<ElementType>,
  ];
};
