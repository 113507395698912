import * as React from 'react';

export const PreloaderContext = React.createContext<PreloaderContextProps | null>(null);

export const PreloaderProvider = ({ children }: PreloaderProviderProps) => {
  const [totalAmountOfAssets, setTotalAmountOfAssets] = React.useState<number>(0);
  const [totalAmountOfAssetsLoaded, setTotalAmountOfAssetsLoaded] = React.useState<number>(0);
  const [allAssetsLoaded, setAllAssetsLoaded] = React.useState(false);
  const [preloaderVisible, setPreloaderVisible] = React.useState(true);

  const addAssetToPreload = (number = 1) => {
    setTotalAmountOfAssets((totalAmountOfAssets) => totalAmountOfAssets + number);
  };

  const addLoadedAsset = (number = 1) => {
    setTotalAmountOfAssetsLoaded((totalAmountOfAssetsLoaded) => totalAmountOfAssetsLoaded + number);
  };

  React.useEffect(() => {
    if (totalAmountOfAssets === 0) return;

    if (totalAmountOfAssets === totalAmountOfAssetsLoaded) {
      setAllAssetsLoaded(true);
    }
  }, [totalAmountOfAssetsLoaded, totalAmountOfAssets]);

  return (
    <PreloaderContext.Provider value={{
      preloaderVisible,
      setPreloaderVisible,
      allAssetsLoaded,
      totalAmountOfAssets,
      totalAmountOfAssetsLoaded,
      addAssetToPreload,
      addLoadedAsset,
    }}>
      {children}
    </PreloaderContext.Provider>
  );
};

type PreloaderContextProps = {
  preloaderVisible: boolean;
  setPreloaderVisible: (bool: boolean) => void;
  allAssetsLoaded: boolean;
  totalAmountOfAssets: number;
  totalAmountOfAssetsLoaded: number;
  addAssetToPreload: (number?: number) => void;
  addLoadedAsset: (number?: number) => void;
}

export type PreloaderProviderProps = {
  children: React.ReactNode;
};
