// This is not the usual apiHelper - it is based on the simplified api services from the Authentication hook
import 'isomorphic-fetch';
import qs from 'qs';

import { ApiRequest, ApiRequestOptions, ApiError, ApiOptions } from './types';

const formatApiOptions = (options: ApiOptions, method: string): ApiRequestOptions => {
  const { path, query, body, withFormData = false, hasJSONResponse = true, headers = [] } = options;
  const formattedBody = !withFormData && body ? JSON.stringify(body) : body;

  return {
    path: `${path}${query ? `?${qs.stringify(query, { encode: false })}` : ''}`,
    options: {
      method,
      headers: {
        ...!withFormData && body ? { 'Content-Type': 'application/json' } : {},
        ...headers,
      },
      ...(body && {
        body: formattedBody,
      }),
    },
    hasJSONResponse,
  };
};

export const apiRequest: ApiRequest = ({
  path, options, hasJSONResponse,
}) => new Promise(async (resolve, reject) => {
  fetch(path, options)
    .then(async (response) => {
      if (response.ok) {
        return response.status !== 204 && hasJSONResponse
          ? response.json()
          : response.text();
      }

      return Promise.reject(response.json());
    })
    .then((json) => { resolve(json); })
    .catch((json) => {
      try {
        json.then((err: ApiError) => {
          reject(err);
        }).catch((err: ApiError) => {
          reject(err);
        });
      } catch (err) {
        reject(json);
      }
    });
});

export const api = {
  get: (options: ApiOptions) => apiRequest(formatApiOptions(options, 'GET')),
  del: (options: ApiOptions) => apiRequest(formatApiOptions(options, 'DELETE')),
  post: (options: ApiOptions) => apiRequest(formatApiOptions(options, 'POST')),
  put: (options: ApiOptions) => apiRequest(formatApiOptions(options, 'PUT')),
  patch: (options: ApiOptions) => apiRequest(formatApiOptions(options, 'PATCH')),
};
