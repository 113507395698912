/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';

type LogType = {
  message?: string;
  [key: string]: any;
};

type ErrorType = {
  type: string;
  [key: string]: any;
};

/* Set extra information when there's a render error on site */
export const handleErrorBoundaryCapture = (scope: Sentry.Scope) => {
  scope.setTag('url', window.location.href);
};

/* When the user is authenticated, inform sentry about the user */
export const setSentryUser = (email?: string) => {
  Sentry.configureScope((scope: Sentry.Scope) => {
    scope.setUser({ email });
  });
};

/* Send a non-error log to Sentry (useful for production debugging) */
export const sendLog = (log: LogType) => {
  /* eslint-disable no-console */
  console.warn('Sentry.Log', log);

  if (process.env.GATSBY_APP_ENV !== 'production') return;

  Sentry.withScope((scope: Sentry.Scope) => {
    scope.setExtras({ log: JSON.stringify(log) });
    Sentry.captureMessage(log.message || 'Undescribed log', Sentry.Severity.Log);
  });
};

/* Send an error-level message to Sentry when it's a business logic error */
export const sendError = (error: ErrorType) => {
  console.error('Sentry.Error', error);

  if (process.env.GATSBY_APP_ENV !== 'production') return;

  Sentry.withScope((scope: Sentry.Scope) => {
    scope.setExtras({ error: JSON.stringify(error) });
    Sentry.captureException(error.message || 'Undescribed error');
  });
};
