import * as React from 'react';

import { HeaderColorContext } from 'services/context/headerColorContext';

export const useHeaderColor = () => {
  const context = React.useContext(HeaderColorContext);

  if (!context) {
    throw new Error('Components should be rendered inside the HeaderColorContext.Provider component');
  }

  return context;
};
