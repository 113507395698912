import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { StyledButton } from 'common/interaction/Button/styled';

export const NewsletterSubscriptionContainer = styled.div<NewsletterSubscriptionContainerProps>`
  margin-bottom: 32px;

  ${({ isBlock, theme }) => isBlock && css`
    margin: ${({ margin }) => margin?.mobile || '8px 8px 12vw'};
    padding: 48px 24px;
    background-color: ${theme.colors.sand.lighter};
    border-radius: 24px;
    text-align: center;

    ${media.desktop<NewsletterSubscriptionContainerProps>`
      max-width: 840px;
      padding: 48px 88px;
      width: 100%;
      margin: ${({ margin }) => margin?.desktop || '0 auto 12vw'};
    `}
  `}
`;

type NewsletterSubscriptionContainerProps = {
  isBlock?: boolean;
  margin?: {
    desktop?: string;
    mobile?: string;
  }
};

export const NewsLetterSubscriptionInputContainer = styled.form`
  display: flex;
  flex-direction: column;

  ${StyledButton} {
    font-size: 16px;
    margin-bottom: 8px;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  ${media.desktop`
    flex-direction: row;
    gap: 16px;

    ${StyledButton} {
      height: 2.5vw;
      width: auto;
      padding-top: 0;
      padding-bottom: 0;
    }
  `}
`;
