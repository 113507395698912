import { isServer } from 'services/isServer';

export const gtagTrackAndOpen = ({
  event,
  url,
  eventName,
  eventCategory,
  eventLabel,
}: gtagTrackAndOpenProps) => {
  event.preventDefault();

  if (!isServer && window.gtag) window.gtag('event', eventName, {
    event_category: eventCategory,
    event_label: eventLabel,
  });

  window.open(url);
};

export const gtagTrack = ({
  eventName,
  eventCategory,
  eventLabel,
}: gtagTrackProps) => {
  if (!isServer && window.gtag) window.gtag('event', eventName, {
    event_category: eventCategory,
    event_label: eventLabel,
  });
};

type gtagTrackProps = {
  eventName: string;
  eventCategory: string;
  eventLabel: string;
};

type gtagTrackAndOpenProps = gtagTrackProps & {
  event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>;
  url: string;
};
