import * as React from 'react';
import Lottie from 'lottie-react';
import { useTransform, useViewportScroll, useMotionTemplate } from 'framer-motion';

import ReadMoreAnimation from 'lottieData/read-more.json';

import { ReadMoreContainer } from './styled';

export const FloatingReadMore: React.FC<FloatingReadMoreProps> = ({ alignRight, animateScroll }) => {
  const { scrollYProgress } = useViewportScroll();
  const bottomValue = useTransform(scrollYProgress, [0.7, 1], [0, -100]);
  const AnimationPositionY = useMotionTemplate`translateY(${bottomValue}vh)`;

  return (
    <ReadMoreContainer
      animateScroll={animateScroll}
      alignRight={alignRight}
      style={{ transform: animateScroll ? AnimationPositionY : 'none' }}
    >
      <Lottie animationData={ReadMoreAnimation} loop />
    </ReadMoreContainer>
  );
};

type FloatingReadMoreProps = {
  alignRight?: boolean;
  animateScroll?: boolean;
};
