import * as i from 'types';
import styled, { css } from 'styled-components';
import { media } from 'styles/utils';

const staticTextSizes = {
  xxl: '48px',
  xl: '22px',
  lg: '18px',
  default: '16px',
  sm: '14px',
  xs: '10px',
  footnote: '8px',
};

const staticTextLineHeights = {
  xxl: '110%',
  xl: '170%',
  lg: '150%',
  default: '130%',
  sm: '130%',
  xs: '130%',
  footnote: 'auto',
};

export const StaticText = styled.p<i.StaticTextDefaultProps>`
  font-size: ${({ $size }) => staticTextSizes[$size || 'default']};
  line-height: ${({ $size }) => staticTextLineHeights[$size || 'default']};
  font-weight: ${({ $weight }) => $weight || 400};
  color: ${({ theme, $color }) => theme.colors[$color || 'text']};
  letter-spacing: -0.01em;

  ${({ $uppercase }) => $uppercase && css`
    text-transform: uppercase;
  `}

  ${({ $isCentered }) => $isCentered && css`
    text-align: center;
  `}

  ${({ $margin }) => $margin && css`
    margin: ${$margin};
  `}

  ${({ $inline }) => $inline && css`
    display: inline-block;
  `}

  ${({ $noSelect }) => $noSelect && css`
    user-select: none;
  `}

  & > a {
    color: ${({ theme, $color }) => theme.colors[$color || 'text']};
    text-decoration: underline;
    font-weight: 400;
  }
`;

const staticButtonTextSizes = {
  default: '16px',
  sm: '14px',
};

export const StaticButtonText = styled.p<i.StaticButtonTextDefaultProps>`
  font-size: ${({ $size }) => staticButtonTextSizes[$size || 'default']};
  line-height: ${({ $size }) => $size === 'sm' ? '16px' : 'auto'};
  font-weight: ${({ $weight }) => $weight || 400};
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  text-transform: ${({ $uppercase }) => $uppercase ? 'uppercase' : 'none'};
  margin: 0;
`;

const staticHeadingSizes = {
  h1: {
    mobile: '60px',
    tablet: '60px',
    desktop: '144px',
    large: '144px',
  },
  h2: {
    mobile: '14vw',
    tablet: '40px',
    desktop: '64px',
    large: '64px',
  },
  h3: {
    mobile: '24px',
    tablet: '24px',
    desktop: '32px',
    large: '32px',
  },
  h4: {
    mobile: '20px',
    tablet: '20px',
    desktop: '24px',
    large: '24px',
  },
  h5: {
    mobile: '18px',
    tablet: '18px',
    desktop: '18px',
    large: '18px',
  },
  h6: {
    mobile: '12px',
    tablet: '12px',
    desktop: '12px',
    large: '12px',
  },
};

const staticLineHeights = {
  h1: {
    mobile: '90%',
    tablet: '90%',
    desktop: '90%',
    large: '90%',
  },
  h2: {
    mobile: '100%',
    tablet: '100%',
    desktop: '90%',
    large: '90%',
  },
  h3: {
    mobile: 'normal',
    tablet: '90%',
    desktop: '34px',
    large: '34px',
  },
  h4: {
    mobile: 'initial',
    tablet: 'initial',
    desktop: 'initial',
    large: 'initial',
  },
  h5: {
    mobile: '90%',
    tablet: '90%',
    desktop: '90%',
    large: '90%',
  },
  h6: {
    mobile: '90%',
    tablet: '90%',
    desktop: '90%',
    large: '90%',
  },
};

export const StaticHeading = styled.h1<i.StaticHeadingDefaultProps>`
  font-weight: 400;
  line-height: ${({ as }) => staticLineHeights[as || 'h1'].mobile};
  font-size: ${({ as }) => staticHeadingSizes[as || 'h1'].mobile};
  font-family: ${({ theme, variant }) => variant === 'display' ? theme.fonts.oggRoman : theme.fonts.suisseIntl};
  text-transform: ${({ $uppercase }) => $uppercase ? 'uppercase' : 'none'};
  color: ${({ theme, color }) => theme.colors[color || 'text']};
  margin: ${({ $margin }) => $margin || '24px 0'};
  text-align: ${({ $isCentered }) => $isCentered ? 'center' : 'left'};

  ${media.tablet<i.StaticHeadingDefaultProps>`
    font-size: ${(props) => staticHeadingSizes[props.as || 'h1'].tablet};
    line-height: ${({ as }) => staticLineHeights[as || 'h1'].tablet};
  `}

  ${media.desktop<i.StaticHeadingDefaultProps>`
    font-size: ${(props) => staticHeadingSizes[props.as || 'h1'].desktop};
    line-height: ${({ as }) => staticLineHeights[as || 'h1'].desktop};
  `}

  ${media.large<i.StaticHeadingDefaultProps>`
    font-size: ${(props) => staticHeadingSizes[props.as || 'h1'].large};
    line-height: ${({ as }) => staticLineHeights[as || 'h1'].large};
  `}
`;

export const Headline3 = styled.h3<i.StaticHeadingDefaultProps>`
  font-weight: 400;
  line-height: 26px;
  font-size: 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text};
  margin: ${({ $margin }) => $margin || '24px 0'};

  ${media.desktop`
    font-size: 24px;
    line-height: 31px;
  `}
`;

export const StaticBlockQuote = styled.blockquote`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;
  display: flex;
  margin: 80px 0;

 &:before {
   content: '“';
   font-size: 56px;
 }

 &:after {
    font-size: 56px;
    content: '”';
    align-self: flex-end;
    margin-bottom: -30px;
  }

  p {
    font-size: 56px;
    line-height: 72px;
    margin: 0;
  }
`;
