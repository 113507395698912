import * as React from 'react';
import './src/styles/global.css';

import { BlogTransitionLayout } from 'common/layout';

export const wrapPageElement = ({ element, props }) => {
  // ONLY HAVE THIS TRANSITION TO THE BLOG PAGES.
  // SO RETURN WITHOUT WRAP FOR OTHER PAGES.

  if (!props.location.pathname.includes('blog')) return element;

  return (
    <BlogTransitionLayout {...props}>{element}</BlogTransitionLayout>
  );
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.hash) return location.hash.substring(1);

  const TRANSITION_DELAY = 0.3 * 1000;
  // if it's a "normal" route
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
  }
  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0];

    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY);
  }

  return false;
};
