import * as React from 'react';
// Lines are commented out because they are not used in the upcoming deploy.
// But they will be needed for the deployment taking place next week..

// import LinkedInIcon from 'vectors/socials/linkedin.svg';
// import FacebookIcon from 'vectors/socials/facebook.svg';
import InstagramIcon from 'vectors/socials/instagram.svg';

import { SocialContainer, IconContainer } from './styled';

export const FooterSocial = () => {
  return (
    <SocialContainer>
      {/* <IconContainer href="https://www.facebook.com/thenowatch/">
        <FacebookIcon />
      </IconContainer> */}
      <IconContainer href="https://www.instagram.com/thenowatch/">
        <InstagramIcon />
      </IconContainer>
      {/* <IconContainer href="https://www.linkedin.com/company/thenowatch/">
        <LinkedInIcon />
      </IconContainer> */}
    </SocialContainer>
  );
};
