import styled from 'styled-components';

import { media } from 'styles/utils';

export const SupportBlockContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.sand.lighter};
  border-radius: 16px;
  padding: 40px 16px;
  margin: 80px 8px;
  width: calc(100% - 16px);
  max-width: 942px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.desktop`
    margin: 80px auto;
    width: 100%;
  `}
`;
