
export const buyNowNavigationClicked = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'click_buy_now_navigation',
  });
};

export const buyNowIntroductionClicked = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'click_buy_now_introduction',
  });
};

export const buyNowStylesClicked = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'click_buy_now_styles',
  });
};

export const buyNowJourneyClicked = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'click_buy_now_journey',
  });
};

export const learnMoreIntroductionClicked = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'click_learn_more_introduction',
  });
};
