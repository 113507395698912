import styled, { css } from 'styled-components';
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';

export const InternalLink = styled(GatsbyLink).attrs((props: LinkProps) => ({
  rel: props.target === '_blank' ? 'noopener noreferrer' : '',
}))<LinkProps>`
  color: ${({ theme, $color }) => theme.colors[$color || 'black']};
  text-decoration: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.suisseIntl};

  ${({ $isBold }) => $isBold && css`
    font-weight: 600;
  `}

  ${({ $withIcon }) => $withIcon && css`
    display: flex;
    align-items: center;

    svg {
      width: 10px;
      margin-right: 6px;
    }
  `}
`;

export const ExternalLink = styled(InternalLink).attrs((props: LinkProps) => ({
  as: props.as ? props.as : 'a',
  target: props.target ? props.target : '_blank',
  rel: 'noopener noreferrer',
  href: props.to,
}))``;

type LinkProps = GatsbyLinkProps<never> & {
  $isBold?: boolean;
  $withIcon?: boolean;
  as?: 'a' | 'button';
  $color?: TextColors;
};
